import * as React from "react"

import {Link, useStaticQuery, graphql} from 'gatsby'
import {StaticImage} from 'gatsby-plugin-image'

import Layout from '../components/layout/layout'
import Blogpost from "../components/blogpost/blogpost"

import parse from "html-react-parser";
import facebook_image from "../images/facebook.png"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTriangleExclamation} from '@fortawesome/free-solid-svg-icons'
import Adsense from "../components/adsense/adsense";

const pageTitle = "Agile Hobby 曉飛網"


const IndexPage = () => {
    const query = graphql`
      {
        restApiFeaturedApi {
          objects {
            article_type
            content_en
            summary_en
            title_en
            slug
            mode_type
            id
            flag_online
            creation_date_timestamp
            creation_date
            thumbnail_url
          }
        }
      }`

    const objects = useStaticQuery(query)

    return (
        <Layout pageTitle={pageTitle}>
                        <Adsense client="ca-pub-6505108883414662" slot="2219379165" format="auto"/>

            <div className="container">
                <div className="row pt-4">
                    <div className="col-md-8 col-12">

                        <img src="https://www.agilehobby.com/lookandfeel/images/banner/banner-fpv.jpg"
                             className="img-fluid"/>
                        <h1 className="mb-3 mt-3">Flying is fun 您可以享受飛翔的快樂</h1>

                        <div className="fs-6">
                            <p>Agile Hobby 曉飛網專門介紹會曉飛的機器。曉飛網主要焦點是多軸直昇機及定翼機。 Agile Hobby 曉飛網的大本營是香港區，但是所有資訊都會是 Nicam
                                中英文雙語廣播，務求所有資料儘量準確。</p>


                            <p>網站將會有嘈交區討論區。有不滿或意見請到:<br/>
                                <a href="https://www.facebook.com/agilehobby">Agile Hobby 面書專頁</a></p>

                            <p>Welcome to Agile Hobby. We cover anything that flies you can get your hands on.</p>

                            <p>All articles and news will be reported both in Chinese and English. Meanwhile, stay tuned
                                and wait for our discussion forum.</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <div className="alert alert-info font-size-12">
                            <h4 className="pb-4">
                                <FontAwesomeIcon icon={faTriangleExclamation}/>&nbsp;
                                Important 重要安全提示
                            </h4>

                            <p>安全第一最重要，千萬不要飛近人群，更加不要在人們頭頂飛過。</p>
                            <p>只在天氣好的情況下飛行。大風或大雨情況下請機下留情。飛行玩意<a
                                href="http://www.nytimes.com/2013/09/06/nyregion/remote-controlled-copter-fatally-strikes-pilot-at-park.html?_r=0"
                                target="_blank">不是玩具</a>，做成 <a
                                href="http://www.thestandard.com.hk/news_detail.asp?pp_cat=11&amp;art_id=119819&amp;sid=35449292&amp;con_type=1&amp;d_str=20120217"
                                target="_blank">意外會後悔莫及</a>。</p>

                            <p>Safety is the most paramount thing with this hobby. Do not fly near city blocks, crowds,
                                power lines and kids.</p>
                            <p>Only fly in good weather. Do not fly in gusty or rainy weather. When you have doubts,
                                stop.
                                There is always chances to fly another day.</p>
                            <p>Accidents do happen. See this <a
                                href="http://www.thestandard.com.hk/news_detail.asp?pp_cat=11&amp;art_id=119819&amp;sid=35449292&amp;con_type=1&amp;d_str=20120217"
                                target="_blank">RC helicopter accident</a> and&nbsp;
                                <a href="http://www.nytimes.com/2013/09/06/nyregion/remote-controlled-copter-fatally-strikes-pilot-at-park.html?_r=0"
                                   target="_blank">this fatal one</a>.</p>

                        </div>
                        <a href="https://www.facebook.com/agilehobby" target="_blank"><img src={facebook_image}
                                                                                           className="img-fluid"
                                                                                           style={{maxWidth: "150px"}}/></a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h2 className="mb-3">Featured articles 焦點文章</h2>
                    </div>

                    {objects && objects.restApiFeaturedApi && objects.restApiFeaturedApi.objects ? objects.restApiFeaturedApi.objects.slice(0, 4).map(post => (

                        <div className="col-md-6 col-12">
                            <Blogpost post={post} noBorder="true"/>

                        </div>

                    )) : null}


                </div>

            </div>

        </Layout>

    )
}

export default IndexPage
