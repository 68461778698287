import React, {useState} from 'react';
import parse from "html-react-parser";
import {Link} from 'gatsby'

const blogpost = ({post, noBorder}) => {
    return (
        <Link to={`/featured/${post.slug}/`} className="blogPost">
            <div className="card mb-3 ">
                <img src={post.thumbnail_url} className="card-img-top" alt="..."/>
                <div className="card-body min-height-150">
                    <h5 className="card-title card-media-title">{post.title_en}</h5>
                    <p className="card-text text-muted font-size-12">{parse(post.summary_en)}</p>
                </div>
            </div>
        </Link>
    )
}

export default blogpost;